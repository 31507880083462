<template>
  <tr class="heigh-row">
    <td>
      <div class="d-flex justify-content-center">
        <span
          v-text="item.count"
          class="text-right"
        ></span>
      </div>
    </td>

    <!-- begin: code input -->
    <td>
      <div v-if="edit">
        <input
          v-model.trim="itemChoice.code"
          :placeholder="itemContent.codePlaceholder"
          class="w-100 h-90 input form-control form-control-sm"
          oninvalid="alert('Nhập đầy đủ thông tin!');"
          style="border-color: #97c7f9"
        />
        <p
          style="margin: 0; color: red"
          v-if="error"
        >
          {{itemContent.codeErrorNoti}}
        </p>
      </div>
      <span v-else>{{ item.code }}</span>
    </td>
    <!-- end: code input -->

    <!-- begin: detail input -->
    <td>
      <div v-if="edit">
        <input
          v-model.trim="itemChoice.detail"
          :placeholder="itemContent.detailPlaceholder"
          class="w-100 h-90 input form-control form-control-sm"
          oninvalid="alert('Nhập đầy đủ thông tin!');"
          style="border-color: #97c7f9"
        />
        <p
          style="margin: 0; color: red"
          v-if="error"
        >
          {{itemContent.detailErrorNoti}}
        </p>
      </div>
      <span v-else>{{ item.detail }}</span>
    </td>
    <!-- end: detail input -->

    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon
            class="mr-2 text-primary icon"
            style="font-size: 18px"
            @click="checkvalidation()"
          >mdi-check</v-icon>
          <v-icon
            class="text-danger icon"
            style="font-size: 18px"
            @click="cancelEdit()"
          >mdi-close</v-icon>
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              @click="editItem()"
              v-show="checkPermission('SYMPTOM_ACTION_CODE_UPDATE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem"
                  class="flaticon2-pen"
                ></i>
                &nbsp; Chỉnh sửa
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showDeleteAlert"
              v-show="checkPermission('SYMPTOM_ACTION_CODE_DELETE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </td>
  </tr>
</template>


<script>
import Swal from 'sweetalert2';
import localData from '@/utils/saveDataToLocal';

export default {
  props: ['item', 'itemContent', 'parentEdit', 'index'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: '',
        code: '',
        detail: '',
      },
      error: false,
    };
  },
  updated() {
    this.checkAddRow();
  },
  beforeMount() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.code = this.item.code;
      this.itemChoice.detail = this.item.detail;
    },
    clearInput() {
      this.itemChoice.id = '';
      this.itemChoice.code = '';
      this.itemChoice.detail = '';
    },
    cancelEdit: function () {
      this.error = false;
      this.edit = false;
      if (this.item.code == '') {
        this.$emit('cancelAdd');
      }
      this.clearInput();
    },
    updateItem: function () {
      this.edit = false;

      if (this.item.code == '') {
        this.item.code = this.itemChoice.code;
        this.item.detail = this.itemChoice.detail;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }

      this.clearInput();
    },
    checkvalidation() {
      if (this.itemChoice.code && this.itemChoice.detail) {
        this.error = false;
        this.updateItem();
      } else {
        this.error = true;
      }
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: this.itemContent.alertTitle,
        text: this.itemContent.alertText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkAddRow() {
      if (this.item.code == '' || this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
  },
};
</script>

<style scoped>
.heigh-row {
  height: 40px;
}
.heigh-row td:nth-child(1) {
  width: 20px;
}
.heigh-row td:nth-child(2) {
  width: 160px;
  padding: 0.75rem;
}
.heigh-row td:nth-child(3) {
  width: 70%;
  padding: 0.75rem;
}

.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}

.v-icon:hover {
  background-color: #90c6fc;
}

table {
  table-layout: fixed;
}
</style>
