<template>
  <div>
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <div class="row">
      <div class="col-md-12">
        <!-- <KTCodePreview v-bind:title="''"> -->
        <!--begin: Wizard-->
        <!-- <template v-slot:preview> -->
        <div
          class="wizard wizard-4"
          id="kt_wizard_v4"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div
            class="wizard-nav"
            style="background-color: #eef0f8"
          >
            <div class="wizard-steps">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">Thông tin mã lỗi</div>
                  </div>
                </div>
              </div>

              <div
                class="wizard-step ml-1"
                data-wizard-type="step"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-label">
                    <div class="wizard-title">Thông tin mã sửa chữa</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <!--begin: Wizard Body-->
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row py-lg-15 px-lg-10">
                <div class="col-xl-12">
                  <!--begin: Wizard Form-->
                  <form
                    class="form mt-0"
                    id="kt_form"
                  >
                    <!--begin: Thông tin mã lỗi-->
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <SymptomActionForm
                        v-if="currentField === field.symptom"
                        :apiPath='`symptom-codes`'
                        :tableHeader='{code:`Mã lỗi`, detail:`Chi tiết lỗi`}'
                        :itemContent='{
                          codePlaceholder:`Mã lỗi`,
                          codeErrorNoti:`Vui lòng nhập mã lỗi`,
                          detailPlaceholder:`Mô tả chi tiết lỗi`,
                          detailErrorNoti:`Vui lòng nhập chi tiết lỗi`,
                          alertTitle:`Xoá mã lỗi`,
                          alertText:`Bạn có chắc muốn xoá mã lỗi này?`
                        }'
                      />
                    </div>
                    <!--end: Thông tin mã lỗi-->
                    <!--begin:Thông tin mã sửa chữa-->
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                    >
                      <SymptomActionForm
                        v-if="currentField === field.action"
                        :apiPath='`action-codes`'
                        :tableHeader='{code:`Mã sửa chữa`, detail:`Chi tiết sửa chữa`}'
                        :itemContent='{
                          codePlaceholder:`Mã sửa chữa`,
                          codeErrorNoti:`Vui lòng nhập mã sửa chữa`,
                          detailPlaceholder:`Mô tả chi tiết sửa chữa`,
                          detailErrorNoti:`Vui lòng nhập chi tiết sửa chữa`,
                          alertTitle:`Xoá mã sửa chữa`,
                          alertText:`Bạn có chắc muốn xoá mã sửa chữa này?`
                        }'
                      />
                    </div>
                    <!--end:Thông tin mã sửa chữa-->

                  </form>
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Bpdy-->
        </div>
        <!-- </template> -->
        <!--end: Wizard-->
        <!-- </KTCodePreview> -->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { mapGetters } from 'vuex';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import SymptomActionForm from '@/view/pages/orders/symptom-action-code/SymptomActionForm.vue';

export default {
  data() {
    return {
      edit: false,
      parentEdit: false,
      totalPages: 0,
      totalItems: 0,
      search: '',
      currentField: 1,
      // thứ tự xuất hiện của các field
      field: {
        symptom: 1,
        action: 2,
      },
    };
  },
  components: {
    Loader,
    SymptomActionForm,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thông tin mã lỗi / mã sửa chữa' },
    ]);

    const updateCurrent = this.updateCurrentField;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      updateCurrent(wizard.currentStep);
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    updateCurrentField(current) {
      this.currentField = current;
    },
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>