var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"wizard wizard-4",attrs:{"id":"kt_wizard_v4","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_vm._m(0),_c('div',{staticClass:"card card-custom card-shadowless rounded-top-0"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"row py-lg-15 px-lg-10"},[_c('div',{staticClass:"col-xl-12"},[_c('form',{staticClass:"form mt-0",attrs:{"id":"kt_form"}},[_c('div',{staticClass:"pb-5 full-width",attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[(_vm.currentField === _vm.field.symptom)?_c('SymptomActionForm',{attrs:{"apiPath":`symptom-codes`,"tableHeader":{code:`Mã lỗi`, detail:`Chi tiết lỗi`},"itemContent":{
                        codePlaceholder:`Mã lỗi`,
                        codeErrorNoti:`Vui lòng nhập mã lỗi`,
                        detailPlaceholder:`Mô tả chi tiết lỗi`,
                        detailErrorNoti:`Vui lòng nhập chi tiết lỗi`,
                        alertTitle:`Xoá mã lỗi`,
                        alertText:`Bạn có chắc muốn xoá mã lỗi này?`
                      }}}):_vm._e()],1),_c('div',{staticClass:"pb-5 full-width",attrs:{"data-wizard-type":"step-content"}},[(_vm.currentField === _vm.field.action)?_c('SymptomActionForm',{attrs:{"apiPath":`action-codes`,"tableHeader":{code:`Mã sửa chữa`, detail:`Chi tiết sửa chữa`},"itemContent":{
                        codePlaceholder:`Mã sửa chữa`,
                        codeErrorNoti:`Vui lòng nhập mã sửa chữa`,
                        detailPlaceholder:`Mô tả chi tiết sửa chữa`,
                        detailErrorNoti:`Vui lòng nhập chi tiết sửa chữa`,
                        alertTitle:`Xoá mã sửa chữa`,
                        alertText:`Bạn có chắc muốn xoá mã sửa chữa này?`
                      }}}):_vm._e()],1)])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-nav",staticStyle:{"background-color":"#eef0f8"}},[_c('div',{staticClass:"wizard-steps"},[_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":"current"}},[_c('div',{staticClass:"wizard-wrapper"},[_c('div',{staticClass:"wizard-label"},[_c('div',{staticClass:"wizard-title"},[_vm._v("Thông tin mã lỗi")])])])]),_c('div',{staticClass:"wizard-step ml-1",attrs:{"data-wizard-type":"step"}},[_c('div',{staticClass:"wizard-wrapper"},[_c('div',{staticClass:"wizard-label"},[_c('div',{staticClass:"wizard-title"},[_vm._v("Thông tin mã sửa chữa")])])])])])])
}]

export { render, staticRenderFns }