<template>
  <div class="pb-5 full-width">
    <div class="col-md-12 mb-5">
      <b-form>
        <div class="row flex-row-reverse">
          <div
            class="ml-3"
            style="padding: 0"
          >
            <div class="d-flex justify-content-end">
              <button
                type="button"
                v-if="checkPermission('SYMPTOM_ACTION_CODE_INSERT')"
                class="btn btn-primary font-weight-bolder btn-sm"
                @click="addRow"
              >
                <i
                  style="font-size: 1rem"
                  class="flaticon2-add-1"
                ></i>Tạo mới
              </button>
            </div>
          </div>
          <b-input
            size="sm"
            placeholder="Tìm kiếm"
            v-model.trim="search"
            append-icon="search"
            single-line
            hide-details
            class="col-md-3"
            @input="onInputChange"
            v-on:keyup.enter="fetchCodeList"
          ></b-input>
        </div>
      </b-form>
    </div>

    <table class="table table-bordered table-vertical-center table-hover">
      <thead>
        <tr>
          <th style="textalign: center;
                                       color: rgb(24, 28, 50);
                                       width: 20px;">
            STT
          </th>
          <th
            style="textalign: center; color: rgb(24, 28, 50)"
            scope="col"
          >
            {{tableHeader.code}}
          </th>
          <th
            style="textalign: center; color: rgb(24, 28, 50)"
            scope="col"
          >
            {{tableHeader.detail}}
          </th>
          <th
            style="width: 20px"
            scope="col"
          ></th>
        </tr>
      </thead>

      <tbody
        v-for="(item, index) in codeList"
        :key="index"
      >
        <SymptomActionItem
          :item="item"
          :parentEdit="parentEdit"
          :index='index'
          :itemContent="itemContent"
          @addItem="addNewSymptomCode"
          @updateItem="updateSymptomCode"
          @deleteItem="deleteSymptomCode"
          @cancelAdd="cancelAdd"
        />
        <slot></slot>
      </tbody>
    </table>

    <b-row>
      <b-col>
        <p
          class="mt-3 text-dark"
          style="font-weight: 500"
        >
          Tổng số mã:
          {{ totalItems }}
        </p>
      </b-col>
      <b-col style="padding-left: 2rem">
        <b-pagination-nav
          v-if="totalPages >= 2"
          class="customPagination"
          :number-of-pages="totalPages"
          @change="fetchCodeList"
          align="right"
          first-class="page-item-first btn btn-icon btn-sm m-1"
          prev-class="page-item-prev btn btn-icon btn-sm m-1"
          next-class="page-item-next btn btn-icon btn-sm m-1"
          last-class="page-item-last btn btn-icon btn-sm m-1"
          page-class="btn btn-icon btn-sm border-0 m-1"
          use-router
          :link-gen="linkGen"
        >
          <template v-slot:first-text>
            <span>
              <i class="ki ki-bold-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-bold-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-bold-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-bold-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import localData from '@/utils/saveDataToLocal';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import SymptomActionItem from '@/view/pages/orders/symptom-action-code/SymptomActionItem.vue';
import ApiService from '@/core/services/api.service';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  data() {
    return {
      codeList: [],
      edit: false,
      parentEdit: false,
      totalPages: 0,
      totalItems: 0,
      page: 1,
      search: '',
    };
  },
  components: {
    SymptomActionItem,
  },
  props: ['apiPath', 'tableHeader', 'itemContent', 'currentField'],
  mounted() {},
  created() {
    if (this.$route.query.page) {
      this.$router.push({ path: '/symptom-action-code' });
    }
    this.fetchCodeList(1);
  },
  methods: {
    addRow() {
      this.edit = true;
      this.parentEdit = this.edit;
      this.$emit('parentEdit', this.parentEdit);
      let data = {
        count: '',
        id: '',
        code: '',
        detail: '',
      };
      if (this.search) {
        return;
      }
      if (!this.codeList[0] || this.codeList[0].id != '') {
        this.codeList.unshift(data);
        return;
      }
    },
    cancelAdd() {
      this.codeList.shift();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchCodeList(page) {
      page ? (this.page = page) : (this.page = this.$route.query.page);

      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }
      if (this.search) {
        this.page = 1;
      }

      let param = {
        page: this.page,
        pageSize: 10,
        search: this.search || '',
      };
      let paramQuery = {
        params: {
          param,
        },
      };

      ApiService.setHeader();
      ApiService.query(this.apiPath, paramQuery).then(({ data: response }) => {
        this.codeList = [];
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalItems;
        this.currentPage = response.data.currentPage;
        response.data.code.forEach((item, index) => {
          let code = {
            count:
              this.currentPage >= 2
                ? index + 1 + this.currentPage * 10 - 10
                : index + 1,
            id: item.id,
            code: item.code,
            detail: item.detail,
          };
          this.codeList.push(code);
          this.parentEdit = false;
        });
      });
    },
    addNewSymptomCode(item) {
      const { code, detail } = item;
      if (!detail.trim() || !code.trim()) {
        return;
      }
      let paramQuery = {
        params: { id: uuidv4(), code, detail },
      };
      ApiService.setHeader();
      ApiService.post(this.apiPath, paramQuery)
        .then(({ data: response }) => {
          const { message } = response;
          this.fetchCodeList();
          this.makeToastSuccess(message);
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
          this.cancelAdd();
          this.addRow();
        });
    },
    updateSymptomCode(item) {
      const { code, detail } = item;

      if (code === '' || detail === '') {
        return;
      }
      let paramQuery = {
        params: { code, detail },
      };
      ApiService.setHeader();
      ApiService.post(this.apiPath + '/' + item.id, paramQuery)
        .then(({ data: response }) => {
          this.fetchCodeList();
          const { message } = response;
          this.makeToastSuccess(message);
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    deleteSymptomCode(id) {
      ApiService.setHeader();
      ApiService.patch(this.apiPath + '/' + id)
        .then(({ data: response }) => {
          console.log('response: ', response);
          const { message } = response;

          this.fetchCodeList();
          this.makeToastSuccess(message);
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    debounceInput: debounce(function () {
      this.fetchCodeList();
    }, TIME_TRIGGER),
    onInputChange(text = '') {
      this.search = text.trim();
      this.debounceInput();
    },
    linkGen: (pageNum) => {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
  },
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.icon-color {
  color: #464e5f;
}
</style>


<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>